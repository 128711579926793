import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import TopBar from './Topbar/Topbar';
import mainRoutes from '../Routes/routes';

export default function PrivateRoutes() {

    return (
        <>
            <TopBar
                menuData={mainRoutes}
            />
            {/* <SideBar
                menuData={mainRoutes}
                panelName="User"
            /> */}

            <Container>
                <Outlet />
            </Container>
        </>
    )
}