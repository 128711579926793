const url = {
  login: "/admin/login",
  exam: {
    createExam: "/exam/create",
    getAllExams: "/exam/getAllExams",
    updateExamById: "/exam/update",
    deleteExam: "/exam/delete",
    getExamById: "/exam/getExam",
    update: "/exam/update",
    computationService: "/mapping/progress/mapping"
  },

  mapping: {
    uploadStudentDataByExamId: "/studentData/uploadStudentData",
    getStudentDataModalField: "/studentData/getStudentDataModelField",
    getCSVHeadersByExamId: "/studentData/getCsvHeaders",
    updateMappingByExamId: "/studentData/updateMapping",
    insertStudentData: "/student/insertStudents",
    uploadCenterData: "/centerData/uploadCenterData",
    uploadCenterFile: "/centerFile/uploadCenterFile",
    getExamStats: "/mapping/getExamStats",
    mappedCandidateData: "/mapping/mapcandidates",
    downloadCSV: "/admin/zip",
    getSteps: "/studentData/getSteps",
    autoPopulateCenters: "/mapping/autoPopulateCenters",
    getAllDistinctCenterPincode :"/centerData/getAllDistinctCenterPincode",
    getAllPinCode:"/student/getAllPinCode",
    mapPincodeCandidate :"/mapping/mapPincodeCandidate",
    mapBySubject: "/mapping/map/subject",
    mapBySubjectParallel: "/mapping/map/parallel/subject",
    downloadCSVResult: "/mapping/progress/download/zip",
    getCenterDataModalField: '/centerFile/getCenterFileModelField',
    getCenterCsvHeader: '/centerFile/getCsvHeaders',
    updateCenterMapping:'/centerFile/updateMapping',
    insertCenterData:'/centerData/insertCenterData'
  },
  step:{
    step1: '/steps/step1',
    step2: '/steps/step2',
    step3: '/steps/step3',
    step4: '/steps/step4',
  }
};

export default url;
