import { faCheckCircle, faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import MyUploader from "../../../../components/Dropzone/dropzone";
import UserDataDropZone from "../../../../components/Dropzone/UserData.dropzone";
import { MappingServices } from "../../../../services/mapping.service";
import StepService from "../../../../services/step.service";
import {useEffect, useState} from "react";

export default function Step2() {
  const { examId } = useParams();
  const [stepData, setStepData] = useState<boolean>(false);

  const data = require("../../../../components/Assets/file/centerData.csv");

  const handleUploadStudentData = async (e: any) => {
    let formData = new FormData();
    formData.append("upload", e);
    toast.promise(MappingServices.uploadCenterData(examId, formData), {
      loading: "Loading..",
      success: "Center Data Uploaded Successfully",
      error: "Error While Uploading Center Data ",
    });
  };

  const getStepData = async () => {
    await StepService.getStep2(examId).then((res) => {
      if (res.status === 200) {
        setStepData(res.data);
      }
    }).catch(e => {
      console.log(e)
      setStepData(false)
    });
  };

  useEffect(() => {
    getStepData();
  }, []);
  return (
    <>
      <Card className="shadow-sm p-1 mb-5 bg-white rounded mt-4">
        <Card.Body>
          <h5 className="mb-1">Upload Center Data</h5>
          <a href="https://enixm-static.s3.ap-south-1.amazonaws.com/MOCK_DATA_EXMMAP.csv" className=" mb-3" download>
            Sample Download
            <FontAwesomeIcon icon={faCloudDownload} className="ms-1" />
          </a>
          { stepData && <div className="mx-3 text-success fw-bold" > 
          Already Uploaded <FontAwesomeIcon icon={faCheckCircle} />
        </div> }
          <div className="mt-2">
            <UserDataDropZone
              handleUploadedFile={(e: any) => handleUploadStudentData(e[0])}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
