import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { AdminServices } from "../../services/admin.service";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface IEditExamModalProps {
    examId: string
    setExamId: any
}

export default function EditExam(props: IEditExamModalProps) {

    const [examName, setExamName] = useState<string>("");

    const [exam, setExam] = useState<any>({});


    const addSubject = () => {
        setExam({ ...exam, subjects: [...exam.subjects, { name: "subject" + (exam.subjects.length + 1), subjectType: subjectTypes[1].value }] })
    }
    
    const changeSubjectName = (subject_index: number, value: string) => {
        let tempExam = { ...exam };
        tempExam.subjects[subject_index].name = value;
        setExam(tempExam);
    }

    const deleteSubject = (subject_index: number) => {
        let tempExam = { ...exam };
        tempExam.subjects.splice(subject_index, 1);
        setExam(tempExam);
    }

    const changeSubjectType = (subject_index: number, value: string) => {
        let tempExam = { ...exam };
        tempExam.subjects[subject_index].subjectType = value;
        setExam(tempExam);
    }

    const updateExam = () => {
        AdminServices.updateExam(props.examId, exam).then((res) => {
            if (res.status === 200) {
                props.setExamId(undefined);
                toast.success("Exam Updated Successfully");
            }
        })
    }

    const getExamById = () => {
        AdminServices.getExamById(props.examId).then((res) => {
            if (res.status === 200) {
                setExam(res.data);
            }
        })
    }


    const addDay = () => {
        setExam({ ...exam, examDays: [...exam.examDays, { name: "Day " + (exam.examDays.length + 1), shifts: [] }] })
    }

    const deleteLastDay = () => {
        let tempExam = { ...exam };
        tempExam.examDays.pop();
        setExam(tempExam);
    }

    const addShift = (days_index:number) => {
        let tempExam = { ...exam };
        tempExam.examDays[days_index].shifts.push({ name: "Shift " + (tempExam.examDays[days_index].shifts.length + 1), shiftType: 'morning' });
        setExam(tempExam);
    }

    const deleteLastShift = (days_index:number) => {
        let tempExam = { ...exam };
        tempExam.examDays[days_index].shifts.pop();
        setExam(tempExam);
    }

    const changeShiftName = (days_index: number, shift_index: number, value: string)  => {
        let tempExam = { ...exam };
        tempExam.examDays[days_index].shifts[shift_index].name = value;
        setExam(tempExam);
    }
    useEffect(() => {
        if (props.examId) {
            getExamById();
        }
    }, [props.examId])

    const shiftTypes = [
        {
            label: "MORNING",
            value: "morning",

        },
        {
            label: "AFTERNOON",
            value: "afternoon",
        },
        {
            label: "EVENING",
            value: "evening",

        }
    ]

    const subjectTypes = [
        {
            label: "PRIMARY",
            value: "primary",
        },
        {
            label: "SECONDARY",
            value: "secondary",
        }
    ]
      
    console.log(exam);

    return (
        <Modal show={props.examId ? true : false} onHide={() => props.setExamId(undefined)} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Edit Exam</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="fw-bold fs-12">
                        Exam Name
                    </Form.Label>
                    <Form.Control type="text" value={exam.examName} disabled={true} placeholder="Enter Exam Name" onChange={(e: any) => setExamName(e.target.value)} />
                </Form.Group>
                <hr className="mb-3" />
                <div className="d-flex justify-content-between">
                    <h5>Subjects</h5>
                    <Button variant="secondary" onClick={addSubject} size="sm">
                        Add Subject
                    </Button>
                </div>
                <Alert variant="warning" className="mt-2">
                    <b>Note:</b> Don't Add Subject if schedule for only one subject
                    <br/>
                    <br/> If you add a subject make sure headers in Uploaded Students CSV exactly match the Subject Name <b>(Case Senstive & Whitespace)</b>
                </Alert>
                <div className="bg-light p-3 mt-2 rounded">
                    {
                        exam.subjects && exam.subjects.length > 0 && exam.subjects.map((subject: any, index: number) => {
                            return (
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className="fw-bold fs-12">
                                                Subject Name
                                            </Form.Label>
                                            <Form.Control type="text" value={subject.name} placeholder="Enter Subject Name" onChange={(e: any) => changeSubjectName(index, e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className="fw-bold fs-12">
                                                Subject Type
                                            </Form.Label>
                                            <div className="d-flex justify-content-between align-items-center">
                                            <Select
                                                options={subjectTypes}
                                                value={subjectTypes.filter((option: any) => option.value === subject.subjectType)}
                                                onChange={(e: any) => changeSubjectType(index, e.value)}
                                                className="w-100"
                                            />
                                            <FontAwesomeIcon icon={faTimesCircle} onClick={() => deleteSubject(index)} className="text-danger ms-2"/>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                   
                                </Row>
                            )
                        })
                    }
                </div>

                <hr className="mb-3" />
                
                <div className="d-flex justify-content-between">
                <h5>Exam Days</h5>
                <div className="d-flex justify-content-end">
                    <Button variant="secondary" onClick={addDay} size="sm">
                        Add Day
                    </Button>
                    <Button variant="danger" onClick={deleteLastDay} size="sm" className="ms-2">
                        Delete Day
                    </Button>
                </div>
                </div>
                <div className="bg-light p-3 mt-2 rounded">
                <Tabs
                    defaultActiveKey={exam && exam.examDays && exam.examDays.length> 0 && exam?.examDays[0]?.name}
                    className="mb-3"
                >
                    {exam.examDays && exam.examDays.length > 0 && exam.examDays.map((days: any, days_index:number) => {
                        return (
                            <Tab eventKey={days.name} title={days.name}>
                                <div className="mt-2">
                                    <div className="d-flex justify-content-end">
                                        <Button variant="outline-secondary" size="sm" onClick={() => addShift(days_index)}>
                                            Add Shift
                                        </Button>
                                        <Button variant="danger" className="ms-2" size="sm" onClick={() => deleteLastShift(days_index)}>
                                            Delete Shift
                                        </Button>
                                    </div>
                                    <div className="mt-2">
                                        {days.shifts && days.shifts.length > 0 && days.shifts.map((shift: any, shift_index: number) => {
                                            return (
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className="fw-bold fs-12">
                                                                Shift Name
                                                            </Form.Label>
                                                            <Form.Control type="text" value={shift.name} placeholder="Enter Shift Name" onChange={(e: any) => changeShiftName(days_index, shift_index, e.target.value)} />
                                                        </Form.Group>
                                                        </Col>
                                                        <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className="fw-bold fs-12">
                                                                Shift Type
                                                            </Form.Label>
                                                            <Select
                                                                options={shiftTypes}
                                                                value={shiftTypes.find((type) => type.value == shift.shiftType)}
                                                                onChange={(e: any) => {
                                                                    let tempExam = { ...exam };
                                                                    tempExam.examDays[days_index].shifts[shift_index].shiftType = e.value;
                                                                    setExam(tempExam);
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Tab>
                        )
                    })}
                </Tabs>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={updateExam}>
                    Update Exam
                </Button>
            </Modal.Footer>
        </Modal>
    )
}