import React from 'react'
import { Row, Col, Table } from 'react-bootstrap'


interface CenterCountProps {
    statsData: any
}
const CenterCount = ({
    statsData

}: CenterCountProps) => {
  return (
    <>
        <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>City</th>
                      <th>Capacity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statsData &&
                      statsData.cityCapacity &&
                      statsData.cityCapacity.map((item: any) => {
                        return (
                          <tr>
                            <td className="fw-bold text-secondary">
                              {item.city}
                            </td>
                            <td>{item.capacity}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
    </>
  )
}

export default CenterCount