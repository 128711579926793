import { faCheck, faCheckCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Card, Container, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import { MappingServices } from "../../../../services/mapping.service";
import Select from "react-select";
import StepService from "../../../../services/step.service";

export default function Step1() {
  const { examId } = useParams();
  const [dataModelFields, setDataModelFields] = useState<any>();
  const [csvHeaders, setCsvHeaders] = useState<any>();
  const [mappingData, setMappingData] = useState<any>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [stepData, setStepData] = useState<any>({});

  const [loading, setLoading] = useState(false);

  const getStudentDataModel = async () => {
    await MappingServices.getStudentDataModalFields(examId)
      .then((res) => {
        if (res.status === 200) {
          setDataModelFields(Object.values(res.data));
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Error While Fetching Modal Fields");

      });
  };

  const getCSVHeadersByExamId = async () => {
    setLoading(true)
    await MappingServices.getCSVHeadersByExamId(examId)
      .then((res) => {
        if (res.status === 200) {
          setCsvHeaders(
            res.data.map((data: any) => {
              return {
                label: data,
                value: data,
              };
            })
          );
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log(e);
        toast.error("Error While Fetching CSV Headers");
        setLoading(false)

      });
  };

  const handleUpdateStudentMapping = async () => {
    toast.promise(
      MappingServices.updateMappingByExamId(examId, mappingData).then((res) => {
        if (res.status === 200) {
          setIsActive(true);
          getStepData();
        }
      }),
      {
        loading: "Loading..",
        success: "Student Data Uploaded Successfully",
        error: "Error While Uploading Student Data ",
      }
    );
  };

  const handleInserStudent = async () => {
    toast.promise(MappingServices.inserStudentData(examId), {
      loading: "Loading..",
      success: "Student Data Uploaded Successfully",
      error: "Error While Sploading Student Data ",
    });
    getStepData();
  };

  const handleInputChange = (e: any, systemValue: any) => {
    setMappingData({ ...mappingData, [systemValue]: e.value });
  };

  const getStepData = async () => {
    await StepService.getStep2(examId)
      .then((res) => {
        if (res.status === 200) {

          setStepData({ studentsInserted: res.data.studentsInserted, isStudentMappingUpdated: res.data.isStudentMappingUpdated })
          if (res.data.updatedMappingData) {
            let updatedMappingData = res.data.updatedMappingData
            delete updatedMappingData._id;
            setMappingData(res.data.updatedMappingData)
          }

        }
      })
      .catch((e) => {
        console.log(e);
        setStepData(false);

      });
  };

  useEffect(() => {
    getStepData();
    getStudentDataModel();
    getCSVHeadersByExamId();
  }, []);

  if (loading) {
    return (
      <Container>
        <div>
          Loading Please Wait...
        </div>
      </Container>
    )
  }

  return (
    <>
      <Container>
        <Card className="xcn-card mt-3 shadow-sm mb-2">
          <h5 className="text-secondary fw-bold">File Mapping</h5>
          <Table borderless striped className="mt-3">
            <thead>
              <tr className="text-secondary">
                <th>#</th>
                <th>System Value</th>
                <th>Map to Sheet Column Name</th>
              </tr>
            </thead>
            <tbody>
              {dataModelFields &&
                dataModelFields.length > 0 &&
                dataModelFields.map((data: any, index: number) => {
                  return (
                    <tr className="xcn-table-tr" key={index}>
                      <td className="fw-bold">{index + 1}</td>
                      <td className="fw-bold xcn-text-14">{data}</td>
                      <td>
                        <Select
                          className="p-5"
                          options={csvHeaders}
                          onChange={(e: any) => handleInputChange(e, data)}
                          value={{
                            label: mappingData?.[data],
                            value: mappingData?.[data],
                          }}
                        // value={{
                        //   label:
                        //     stepData?.updatedMappingData[data],
                        //   value:
                        //     stepData?.updatedMappingData[data],
                        // }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>

          <div className="d-flex justify-end align-items-center">
            <XcnButton
              text={<>Step 1: Update Student Mapping
                {stepData.isStudentMappingUpdated && <FontAwesomeIcon icon={faCheckCircle} className="ms-2" />}
              </>}
              variant="secondary"
              onClick={handleUpdateStudentMapping}
            />

            <XcnButton
              text={<>Step 2: Insert Student Data
                {stepData.studentsInserted && <FontAwesomeIcon icon={faCheckCircle} className="ms-2" />}
              </>}
              variant="warning"
              onClick={handleInserStudent}
              className="ms-3"
              disabled={!isActive}
            />
          </div>
        </Card>
      </Container>
    </>
  );
}
