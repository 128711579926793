import React from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import Select from 'react-select'

interface NotMappingByDistanceProps {
    studentCities: any
    centerData: any
    handleCenterSelection: any
    getCenterValue: any
    cityMapping: any[]
    setCityMapping: any
}


const NotMappingByDistance = ({
    studentCities,
    centerData,
    handleCenterSelection,
    getCenterValue,
    cityMapping,
    setCityMapping

}: NotMappingByDistanceProps) => {

  const autoPopulateByName = () => {
    let tempCityMapping = []
    for(let city of studentCities){
      let center = centerData.find((center: any) => center.label === city)
      if(center){
        tempCityMapping.push({studentCity: city, centerCity: [center.value]})
      }
    }
    setCityMapping(tempCityMapping)
  }
  
  return (
    <>
    <Row className='mt-3'>
      <Col md={6}>
        <Button onClick={autoPopulateByName} size="sm">
          Auto Populate Centers by Name
        </Button>
      </Col>
    </Row>
     <Row>
          <Col md={12} className="mt-2">
            <Card>
              <Card.Body>
                <Form.Group className="mb-2">
                  <Row>
                    <Col md={6}>
                      <Form.Label className="text-muted fw-bold">
                        Student Choices
                      </Form.Label>
                    </Col>
                    <Col md={6}>
                      <Form.Label className="text-muted fw-bold">
                        Center Cities
                      </Form.Label>
                    </Col>
                  </Row>
                </Form.Group>
                <div>
                  {studentCities.map((item: any) => {
                    return (
                      <>
                        <Row className="my-2">
                          <Col md={6}>{item}</Col>
                          <Col md={6}>
                            <Select
                              options={centerData}
                              onChange={(e: any) =>
                                handleCenterSelection(e, item)
                              }
                              isMulti
                              value={getCenterValue(item)}
                            // value={cityMapping.find( [item]}
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </>
  )
}

export default NotMappingByDistance