import React from 'react'
import { Row, Col, Card, Form, Table, Tabs, Tab } from 'react-bootstrap'


interface RequiredCountProps {
    statsData: any
}

const RequiredCount = ({
    statsData
}: RequiredCountProps) => {
  return (
    <>
    <Row>
              <Col>
                <Tabs defaultActiveKey="choice_1">
                  <Tab eventKey="choice_1" title="Choice 1">
                    <Table>
                      <thead>
                        <tr>
                          <th>City</th>
                          <th>Student Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statsData.requiredCount.city1 &&
                          Object.keys(statsData.requiredCount.city1).map(
                            (city) => {
                              return (
                                <tr>
                                  <td className="fw-bold text-secondary">
                                    {city}
                                  </td>
                                  <td>{statsData.requiredCount.city1[city]}</td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="choice_2" title="Choice 2">
                    <Table>
                      <thead>
                        <tr>
                          <th>City</th>
                          <th>Student Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statsData.requiredCount.city2 &&
                          Object.keys(statsData.requiredCount.city2).map(
                            (city) => {
                              return (
                                <tr>
                                  <td className="fw-bold text-secondary">
                                    {city}
                                  </td>
                                  <td>{statsData.requiredCount.city2[city]}</td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="choice_3" title="Choice 3">
                    <Table>
                      <thead>
                        <tr>
                          <th>City</th>
                          <th>Student Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statsData.requiredCount.city3 &&
                          Object.keys(statsData.requiredCount.city3).map(
                            (city) => {
                              return (
                                <tr>
                                  <td className="fw-bold text-secondary">
                                    {city}
                                  </td>
                                  <td>{statsData.requiredCount.city3[city]}</td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
    </>
  )
}

export default RequiredCount