import { faCheck, faCheckCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Card, Container, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import { MappingServices } from "../../../../services/mapping.service";
import Select from "react-select";
import StepService from "../../../../services/step.service";

export default function Step2b() {
  const { examId } = useParams();
  const [dataModelFields, setDataModelFields] = useState<any>();
  const [csvHeaders, setCsvHeaders] = useState<any>();
  const [mappingData, setMappingData] = useState<any>({dayMapping: []});
  const [isActive, setIsActive] = useState<boolean>(false);
  const [stepData, setStepData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [dayHeaders, setDayHeaders] = useState<any>();
  const [serialNumber,setSerialNumber] = useState<number>(1);

  const getStudentDataModel = async () => {
    await MappingServices.getCenterDataModalFields(examId)
      .then((res) => {
        if (res.status === 200) {
          const combinedHeaders: any = [];

          if (res.data?.internalHeaders) {
            res.data.internalHeaders.map((item: any) => {
              combinedHeaders.push(item);
            });
          }
          const transformedData = res.data.dayHeaders.flatMap((item: any) =>
            item.shifts.map((shift: any) => ({
              dayId: item._id,
              shiftId: shift._id,
              value: `${item.name} ${shift.name}`
            }))
          );

          setDayHeaders(transformedData);
          setDataModelFields(combinedHeaders);
          setSerialNumber(dataModelFields.length);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Error While Fetching Modal Fields");

      });
  };

  const getCSVHeadersByExamId = async () => {
    setLoading(true)
    await MappingServices.getCenterCSVHeadersByExamId(examId)
      .then((res) => {
        if (res.status === 200) {

          setCsvHeaders(
            res.data.map((data: any) => {
              return {
                label: data,
                value: data,
              };
            })
          );
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log(e);
        toast.error("Error While Fetching CSV Headers");
        setLoading(false)

      });
  };

  const handleUpdateCenterMapping = async () => {
    toast.promise(
      MappingServices.updateCenterMapping(examId, mappingData).then((res) => {
        if (res.status === 200) {
          setIsActive(true);
          getStepData();
        }
      }),
      {
        loading: "Loading..",
        success: "Center Data Uploaded Successfully",
        error: "Error While Uploading Student Data ",
      }
    );
  };

  const handleInsertCenter = async () => {
    toast.promise(MappingServices.insertCenterData(examId), {
      loading: "Loading..",
      success: "Center Data Uploaded Successfully",
      error: "Error While Sploading Student Data ",
    });
    getStepData();
  };

  const handleInputChange = (e: any, systemValue: any) => {
    setMappingData((prevMappingData:any) => ({
      ...prevMappingData,
      [systemValue]: e.value // Update specific key without erasing previous data
    }));
    console.log(mappingData);
  };

  const handleDayHeaderChange = (shiftId:any,dayId:any,headerId:any)=>{
    setMappingData((prevMappingData:any) => {
      if (!prevMappingData.dayMapping) {
          return {
              ...prevMappingData,
              dayMapping: [{ shiftId, dayId, headerId }]
          };
      } else {
          const existingIndex = prevMappingData.dayMapping.findIndex(
              (mapping:any) => mapping.shiftId === shiftId
          );

          if (existingIndex !== -1) {
              const updatedDayMapping = [...prevMappingData.dayMapping];
              updatedDayMapping[existingIndex] = { shiftId, dayId, headerId };
              return { ...prevMappingData, dayMapping: updatedDayMapping };
          } else {
              return {
                  ...prevMappingData,
                  dayMapping: [...prevMappingData.dayMapping, { shiftId, dayId, headerId }]
              };
          }
      }
  });
  console.log(mappingData);
  }

  const getStepData = async () => {
    // await StepService.getStep2(examId)
    //   .then((res) => {
    //     if (res.status === 200) {

    //       setStepData({ studentsInserted: res.data.studentsInserted, isStudentMappingUpdated: res.data.isStudentMappingUpdated })
    //       if (res.data.updatedMappingData) {
    //         let updatedMappingData = res.data.updatedMappingData
    //         delete updatedMappingData._id;
    //         setMappingData(res.data.updatedMappingData)
    //       }

    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     setStepData(false);

    //   });
  };

  useEffect(() => {
    getStepData();
    getStudentDataModel();
    getCSVHeadersByExamId();
  }, []);

  if (loading) {
    return (
      <Container>
        <div>
          Loading Please Wait...
        </div>
      </Container>
    )
  }
  return (
    <>
      <Container>
        <Card className="xcn-card mt-3 shadow-sm mb-2">
          <h5 className="text-secondary fw-bold">Center Mapping</h5>
          <Table borderless striped className="mt-3">
            <thead>
              <tr className="text-secondary">
                <th>#</th>
                <th>System Value</th>
                <th>Map to Sheet Column Name</th>
              </tr>
            </thead>
            <tbody>
              {dataModelFields &&
                dataModelFields.length > 0 &&
                dataModelFields.map((data: any, index: number) => {
                  return (
                    <tr className="xcn-table-tr" key={index}>
                      <td className="fw-bold">{index + 1}</td>
                      <td className="fw-bold xcn-text-14">{data}</td>
                      <td>
                        <Select
                          className="p-5"
                          options={csvHeaders}
                          onChange={(e: any) => handleInputChange(e, data)}
                          value={{
                            label: mappingData?.[data],
                            value: mappingData?.[data],
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tbody>
            {dayHeaders && 
                dayHeaders.map((item:any,index:any)=>{
                  return (
                    <tr className="xcn-table-tr" key={index+serialNumber}>
                      <td className="fw-bold">{index + 1 + serialNumber}</td>
                      <td className="fw-bold xcn-text-14">{item.value}</td>
                      <td>
                        <Select
                          className="p-5"
                          options={csvHeaders}
                          onChange={(e: any) => handleDayHeaderChange(item.shiftId,item.dayId,e.value)}
                          value={{
                            label: (mappingData && mappingData.dayMapping && mappingData.dayMapping.find((mapping: any) => mapping.shiftId === item.shiftId && mapping.dayId === item.dayId)?.headerId) || null,
                            value: (mappingData && mappingData.dayMapping && mappingData.dayMapping.find((mapping: any) => mapping.shiftId === item.shiftId && mapping.dayId === item.dayId)?.headerId) || null,
                        }}
                        />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>

          <div className="d-flex justify-end align-items-center">
            <XcnButton
              text={<>Step 1: Update Center Mapping
                {stepData.isStudentMappingUpdated && <FontAwesomeIcon icon={faCheckCircle} className="ms-2" />}
              </>}
              variant="secondary"
              onClick={handleUpdateCenterMapping}
            />

            <XcnButton
              text={<>Step 2: Insert Center Data
                {stepData.studentsInserted && <FontAwesomeIcon icon={faCheckCircle} className="ms-2" />}
              </>}
              variant="warning"
              onClick={handleInsertCenter}
              className="ms-3"
              disabled={!isActive}
            />
          </div>
        </Card>
      </Container>
    </>
  );
}
