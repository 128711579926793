import makeRequest, { RequestMethods } from "./makerequest";
import makeUploadRequest from "./uploadRequest";
import url from "./urls";

export class MappingServices {
  static async uploadStudentDataByExamId(examId: any, payload: any) {
    return await makeUploadRequest(
      url.mapping.uploadStudentDataByExamId + "/" + examId,
      RequestMethods.POST,
      payload
    );
  }

  static async getStudentDataModalFields(examId: any) {
    return await makeRequest(
      url.mapping.getStudentDataModalField + "/" + examId,
      RequestMethods.GET
    );
  }

  static async getCSVHeadersByExamId(examId: any) {
    return await makeRequest(
      url.mapping.getCSVHeadersByExamId + "/" + examId,
      RequestMethods.GET
    );
  }

  static async updateMappingByExamId(examId: any, payload: any) {
    return await makeRequest(
      url.mapping.updateMappingByExamId + "/" + examId,
      RequestMethods.PUT,
      payload
    );
  }

  static async inserStudentData(examId: any) {
    return await makeRequest(
      url.mapping.insertStudentData + "/" + examId,
      RequestMethods.POST
    );
  }

  static async uploadCenterData(examId: any, payload: any) {
    return await makeUploadRequest(
      url.mapping.uploadCenterFile + "/" + examId,
      RequestMethods.POST,
      payload
    );
  }
  static async getAllDistinctCenterPincode(examId: any) {
    return await makeRequest(
      url.mapping.getAllDistinctCenterPincode + "/" + examId,
      RequestMethods.GET
    );
  }

  static async getExamStats(examId: any) {
    return await makeRequest(
      url.mapping.getExamStats + "/" + examId,
      RequestMethods.GET
    );
  }

  static async getAllPinCode(examId: any) {
    return await makeRequest(
      url.mapping.getAllPinCode + "/" + examId,
      RequestMethods.GET
    );
  }

  static async mappedCandidateData(examId: any, payload: any) {
    return await makeRequest(
      url.mapping.mappedCandidateData + "/" + examId,
      RequestMethods.POST,
      payload
    );
  }

  static async mapPincodeCandidate(examId: any, payload: any) {
    return await makeRequest(
      url.mapping.mapPincodeCandidate + "/" + examId,
      RequestMethods.POST,
      payload
    );
  }

  static async mapStudents(examId: any, cityMapping: any, filters: any) {
    return await makeRequest(
      url.mapping.mappedCandidateData + "/" + examId,
      RequestMethods.POST,
      {
        cityMapping,
        filters,
      }
    );
  }

  static async downloadSCV(examId: any) {
    return await makeRequest(
      url.mapping.downloadCSV + "/" + examId,
      RequestMethods.GET
    );
  }

  static async getStepsData(examId: any) {
    return await makeRequest(
      url.mapping.getSteps + "/" + examId,
      RequestMethods.GET
    );
  }

  static async autoPopulateCenters(examId: any, maxDistance: string) {
    return await makeRequest(
      url.mapping.autoPopulateCenters + "/" + examId,
      RequestMethods.POST, {
        distance: maxDistance
      }
    );
  }

  static async mapBySubject(examId: any, cityMapping: any, filters: any) {
    return await makeRequest(
      url.mapping.mapBySubject + "/" + examId,
      RequestMethods.POST,
      {
        cityMapping,
        filters,
      }
    )
  }

  static async mapBySubjectParallel(examId: any, cityMapping: any, filters: any) {
    return await makeRequest(
      url.mapping.mapBySubjectParallel + "/" + examId,
      RequestMethods.POST,
      {
        cityMapping,
        filters,
      }
    )
  }
  static async downloadZipResult(examId: any) {
    return await makeRequest(url.mapping.downloadCSVResult + "/" + examId, RequestMethods.GET);
  }

  static async getCenterDataModalFields(examId: any) {
    return await makeRequest(
      url.mapping.getCenterDataModalField + "/" + examId,
      RequestMethods.GET
    );
  }

  static async getCenterCSVHeadersByExamId(examId: any) {
    return await makeRequest(
      url.mapping.getCenterCsvHeader + "/" + examId,
      RequestMethods.GET
    );
  }

  static async updateCenterMapping(examId:any, data:any){
    return await makeRequest(
      url.mapping.updateCenterMapping + '/' + examId,
      RequestMethods.PUT,
      data
    );
  }

  static async insertCenterData(examId:any){
    return await makeRequest(
      url.mapping.insertCenterData + '/' + examId,
      RequestMethods.POST
    )
  }

}
