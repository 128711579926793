import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import CsvDownloadButton from "react-json-to-csv";
import { MappingServices } from "../../services/mapping.service";
import toast from "react-hot-toast";

import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

interface IStep4ModalProps {
  show: any;
  setShow: any;
  //   onConfirm: any;
  //   loading: boolean;
  filtersData: any;
  examId: any;
  cityMapping: any;
}
export default function ShowMappingCSV(props: IStep4ModalProps) {
  const [finalData, setFinalData] = useState<any>({});

  const shouldExpandNode = () => false;

  useEffect(() => {
    handleSave();
  }, []);
  useEffect(() => {
    handleSave();
  }, [props.show]);

  const handleSave = async () => {
    if (props.show) {
      await MappingServices.mapStudents(
        props.examId,
        props.cityMapping,
        props.filtersData
      )
        .then((res) => {
          if (res.status === 200) {
            setFinalData(res.data);
            toast.success("Students mapped");
          }
        })
        .catch((err) => {
          toast.error(err.response.data);
        });
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      size="lg"
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title>Mapping CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <p>Are you sure you want to confirm this mapping?</p>
        <Tabs
          defaultActiveKey="alloted_canidates"
          id="uncontrolled-tab-example"
          className="mb-3 "
        >
          <Tab eventKey="alloted_canidates" title="Alloted Canidates">
            Alloted Canidates
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Subject</th>
                  <th>City</th>
                  <th>Day</th>
                  <th>Shift</th>
                  <th>Center</th>
                </tr>
              </thead>
              <tbody>
                {finalData?.alloted_canidates?.map(
                  (candidate: any, index: any) => (
                    <tr key={index}>
                      <td>{candidate._id}</td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.name}</td>
                            )
                          )}
                        </tr>
                      </td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.alloted_city}</td>
                            )
                          )}
                        </tr>
                      </td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.alloted_day}</td>
                            )
                          )}
                        </tr>
                      </td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.alloted_shift}</td>
                            )
                          )}
                        </tr>
                      </td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.alloted_center}</td>
                            )
                          )}
                        </tr>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="not_alloted_candidates" title="Not Alloted Candidates">
            Not Alloted Candidates
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Subject</th>
                  <th>City</th>
                  <th>Day</th>
                  <th>Shift</th>
                  <th>Center</th>
                </tr>
              </thead>
              <tbody>
                {finalData?.not_alloted_candidates?.map(
                  (candidate: any, index: any) => (
                    <tr key={index}>
                      <td>{candidate._id}</td>

                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.name}</td>
                            )
                          )}
                        </tr>
                      </td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.alloted_city}</td>
                            )
                          )}
                        </tr>
                      </td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.alloted_day}</td>
                            )
                          )}
                        </tr>
                      </td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.alloted_shift}</td>
                            )
                          )}
                        </tr>
                      </td>
                      <td>
                        <tr className="d-flex flex-column ">
                          {candidate?.alloted_subjects?.map(
                            (info: any, index: any) => (
                              <td>{info?.alloted_center}</td>
                            )
                          )}
                        </tr>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="days_subject" title="Days Subject">
            Days Subject
            {finalData?.days_subject && (
              <JsonView
                data={finalData?.days_subject}
                shouldExpandNode={shouldExpandNode}
                style={defaultStyles}
              />
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}
