import { useEffect, useState } from "react";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import Lottie from 'react-lottie';

import computation_json from "../../../../assets/lottie/computation.json";
import completeTask_json from "../../../../assets/lottie/complete-task.json"

import { useParams } from "react-router-dom";
import { AdminServices } from "../../../../services/admin.service";
import { MappingServices } from "../../../../services/mapping.service";
import toast from "react-hot-toast";

export default function ProgressStatus() {

    const { examId } = useParams();

    const [computation, setComputation] = useState({
        progress: 100, 
        ongoing: false, 
        creatingCsv: false, 
        readyToDownload: false, 
        folderPath: undefined
    });


    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: computation.ongoing ? computation_json : completeTask_json,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };


    const computationStatus = async() => {
        await AdminServices.computationService(examId).then(res => {
            if(res.status == 200){
                setComputation(res.data.progress)
            }
        }).catch(e => {
            console.log(e)
        })
    }


    const downloadZip = async() => {
        toast.promise(
        MappingServices.downloadZipResult(examId).then(res => {
            if(res.status == 200){
                window.open(res.data, "_blank");
            }
        }),
        {
          loading: "Downloading..",
          success: "Download Successfully",
          error: "Error While Downloading Zip File ",
        })
    }

    useEffect(() => {
        computationStatus();
    },[examId])
    return (
        <>
            <Row>
                <Col md={6}>
                    <h4>Progress Status</h4>
                </Col>
                <Col md={6} className="d-flex justify-content-end align-items-center">
                    <Button size="sm" onClick={computationStatus}>
                        Refresh
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h6>
                        Status of the Mapping Process ({computation.progress}% Completed)
                    </h6>
                    <ProgressBar now={computation.progress} />
                    Please Wait Till the Process is Completed
                </Col>
            </Row>
            <hr/>
             <Row>
                <Lottie options={defaultOptions} height={computation.ongoing ? 250 : 300} width={computation.ongoing? 500: 300} />
            </Row>
            <Row className="mt-3">
                <Col>
                    <h6>
                        Computed Files
                    </h6>
                    <Button size="sm" onClick={downloadZip} disabled={!computation.readyToDownload}>
                       {!computation.readyToDownload ?  "Please Wait till computation is complete" : "Files Ready to Download"}
                    </Button>
                </Col>
            </Row>
           
        </>
    )
}