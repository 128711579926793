import ExamIndex from "../views/Dashboard/Exam/Exam"
import MappingIndex from "../views/Dashboard/Mapping/Mapping"

export interface IRouter {
    path: string
    navbarShow: Boolean
    element: any
    name: string
}


const mainRoutes: IRouter[] = [
    {
        path: "exam",
        navbarShow: true,
        element: ExamIndex,
        name: "Exam",
    },
    {
        path: "mapping/:examId",
        navbarShow: false,
        element: MappingIndex,
        name: "Mapping",
    },
    
]


export default mainRoutes;