import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AdminServices } from "../../services/admin.service";
import { toast } from "react-toastify";

interface IAddExamModalProps {
    show: boolean
    setShow: any
}

export default function AddExamModal (props: IAddExamModalProps) {

    const [examName, setExamName] = useState<string>("");

    const createExam = () => {
        AdminServices.createExam({examName: examName}).then((res) => {
            if (res.status === 200) {
                props.setShow(false);
                toast.success("Exam Created Successfully");
            }
        })
    }


    return (
        <Modal show={props.show} onHide={() => props.setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Exam</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold fs-12">
                    Exam Name
                </Form.Label>
                <Form.Control type="text" placeholder="Enter Exam Name" onChange={(e:any) => setExamName(e.target.value)}/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={createExam}>
            Create Exam
          </Button>
        </Modal.Footer>
      </Modal>
    )
}