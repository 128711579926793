import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import CsvDownloadButton from 'react-json-to-csv'

interface IStep4ModalProps {
    show: boolean
    setShow: any
    onConfirm: any
    loading: boolean
    finalData: any
}
export default function Step4Modal(props: IStep4ModalProps) {
    return (
        <Modal show={props.show} onHide={() => props.setShow(false)} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Mapping</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to confirm this mapping?</p>
                <div className="d-flex justify-content-end">
                    <Button size="sm" onClick={props.onConfirm} disabled={props.loading}>{props.loading ? "Loading" : "Confirm"}</Button>
                </div>
                {props.finalData && (props.finalData.not_alloted_students || props.finalData.alloted_students) && <div className="mt-3">
                    <hr />
                    <Row>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 className="text-muted">Not Allocated Students</h6>
                                        </div>
                                        <div className="text-danger h3">
                                            {props.finalData.not_alloted_students.length}
                                        </div>
                                    </div>
                                    <div>
                                    <CsvDownloadButton data={props.finalData.not_alloted_students} className="btn btn-outline-danger btn-sm">
                                            Download CSV
                                        </CsvDownloadButton>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 className="text-muted">Allocated Students</h6>
                                        </div>
                                        <div className="text-success h3">
                                            {props.finalData.alloted_students.length}
                                        </div>
                                    </div>
                                    <div>

                                        <CsvDownloadButton data={props.finalData.alloted_students} className="btn btn-outline-success btn-sm">
                                            Download CSV
                                        </CsvDownloadButton>

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col></Row>
                </div>}
            </Modal.Body>
        </Modal>
    )
}