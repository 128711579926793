import { useContext } from "react";
import { Card, Container } from "react-bootstrap";
import JobCounterStatus, {
  JobStatusEnum,
} from "../../../components/JobCounter";
import { MappingContext } from "./context";

export interface IJobStepInterface {
  stepName: string;
  component?: any;
}

interface IJobStepCounter {
  steps: IJobStepInterface[];
  selectedStep: number;
}

export default function MappingBar({ steps, selectedStep }: IJobStepCounter) {
  const { mappingData }: any = useContext(MappingContext);
  return (
    <Container>
      <Card className="xcn-card mt-3">
        <div className="d-flex justify-content-start align-items-center">
          {steps.map((data: IJobStepInterface, index: number) => {
            // const indexData = mappingData?.find((data: any) => {
            //   return data != selectedStep;
            // });
            // console.log({ indexData });
            return (
              <div key={selectedStep} className="me-4">
                <JobCounterStatus
                  counter={index + 1}
                  stepName={data.stepName}
                    status={
                      index === selectedStep
                        ? JobStatusEnum.INPROGRESS
                        : index < selectedStep
                        ? JobStatusEnum.COMPLETED
                        : JobStatusEnum.NOTVISITED
                    }
                  // status={indexData}
                  key={selectedStep}
                />
              </div>
            );
          })}
        </div>
      </Card>
    </Container>
  );
}
