import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <div className="App">
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            className: "",
            // duration: 6000,
            success: {
              duration: 3000,
            },
          }}
        />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
