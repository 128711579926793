import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import MyUploader from "../../../../components/Dropzone/dropzone";
import UserDataDropZone from "../../../../components/Dropzone/UserData.dropzone";
import { MappingServices } from "../../../../services/mapping.service";
import { MappingContext } from "../context";
import StepService from "../../../../services/step.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faDownload } from "@fortawesome/free-solid-svg-icons";

export default function Step0() {
  const { examId } = useParams();

  const { mappingData, setMappingData }: any = useContext(MappingContext);

  const [stepData, setStepData] = useState<boolean>(false);

  const handleUploadStudentData = async (e: any) => {
    let formData = new FormData();
    formData.append("upload", e);
    toast.promise(MappingServices.uploadStudentDataByExamId(examId, formData), {
      loading: "Loading..",
      success: "Student Data Uploaded Successfully",
      error: "Error While Sploading Student Data ",
    });
  };

  const getStepData = async () => {
    await StepService.getStep1(examId).then((res) => {
      if (res.status === 200) {
        setStepData(res.data);
      }
    }).catch(e => {
      console.log(e)
      setStepData(false)
    });
  };

  useEffect(() => {
    getStepData();
  }, []);

  return (
    <>
      <Card className="shadow-sm p-1 mb-5 bg-white rounded mt-4">
        <h5 className="m-3">Upload Student Data</h5>
        <a href="https://enixm-static.s3.ap-south-1.amazonaws.com/map_student_data.csv" className="mx-3 text-primary">Download Sample File <FontAwesomeIcon icon={faDownload}/></a>
        { stepData && <div className="mx-3 text-success fw-bold" > 
          Already Uploaded <FontAwesomeIcon icon={faCheckCircle} />
        </div> }
        <Card.Body>
          <UserDataDropZone
            handleUploadedFile={(e: any) => handleUploadStudentData(e[0])}
          />
        </Card.Body>
      </Card>
    </>
  );
}
