import makeRequest, { RequestMethods } from "./makerequest";
import url from "./urls";

export class AdminServices {
  static async Login(payload: any) {
    return await makeRequest(url.login, RequestMethods.POST, payload);
  }

  static async createExam(payload: any) {
    return makeRequest(url.exam.createExam, RequestMethods.POST, payload);
  }

  static async updateExam(examId: string, payload: any) {
    return makeRequest(url.exam.update + "/" + examId, RequestMethods.PUT, payload);
  }

  static async getAllExams() {
    return makeRequest(url.exam.getAllExams, RequestMethods.GET);
  }

  static async updateExamById(examId: any, payload: any) {
    return makeRequest(
      url.exam.updateExamById + "/" + examId,
      RequestMethods.PUT,
      payload
    );
  }

  static async deleteExamById(examId: any) {
    return makeRequest(
      url.exam.deleteExam + "/" + examId,
      RequestMethods.DELETE
    );
  }

  static async getExamById(examId: any) {
    return makeRequest(url.exam.getExamById + "/" + examId, RequestMethods.GET);
  }
  
  static async computationService(examId: any) {
    return makeRequest(
      url.exam.computationService + "/" + examId,
      RequestMethods.GET
    );
  }
}
