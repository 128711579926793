import { useEffect, useState } from "react";
import { Card, Col, Row, Form, Button, Tabs, Tab } from "react-bootstrap";
import { useAsyncError, useParams } from "react-router-dom";
import { MappingServices } from "../../../../services/mapping.service";
import Select from "react-select";
import Step4Modal from "../../../../components/Modals/Step4.modal";
import toast from "react-hot-toast";
import MappingByDistance from "./MapByDistance.index";
import NotMappingByDistance from "./NotMappingByDistance.index";
import CenterCount from "./CenterCount.index";
import RequiredCount from "./RequiredCount.index";
import ShowMappingCSV from "../../../../components/Modals/ShowMappingCSV.modal";
import ProgressStatus from "./ProgressStatus.index";
import { AdminServices } from "../../../../services/admin.service";


const formulas = [
  {
    value: "Basic Allocation",
    label: "Basic Allocation",
  },
  {
    value: "Subject Wise Allocation",
    label: "Subject Wise Allocation",
  },
  {
    value: "Subject Wise Allocation - Parallel",
    label: "Subject Wise Allocation - Parallel",
  }
]

export default function Step4() {
  const { examId } = useParams();

  const [filtersData, setFiltersData] = useState<any>({
    pwd_center_mandatory: true,
    females_in_morning: false,
    pwd_in_morning: true,
    male_choice: 1,
    female_choice: 1,
    shuffle_students: false,
    testing: true,
  });
  const [statsData, setStatsData] = useState<any>({});

  const [computation, setComputation] = useState({
    progress: 100, 
    ongoing: false, 
    creatingCsv: false, 
    readyToDownload: false, 
    folderPath: undefined
});

  const [centerData, setCenterData] = useState<any>();
  const [studentCities, setStudentCities] = useState<any>([]);

  const [selectedTab, setSelectedTab] = useState<string>("allotment");

  const [cityMapping, setCityMapping] = useState<any>([]);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [mappingDataRes, setMappingDataRes] = useState<any>({});

  const [maxDistance, setMaxDistance] = useState("100");

  const [mapByDistance, setMapByDistance] = useState(false);

  const [pincodeMappingData, setPincodeMappingData] = useState<any>([]);

  const [selectedFormula, setSelectedFormula] = useState<any>(formulas[0]);


  const computationStatus = async() => {
    await AdminServices.computationService(examId).then(res => {
        if(res.status == 200){
            setComputation(res.data.progress)
        }
    }).catch(e => {
        console.log(e)
    })
}



  const handleFiltersChange = (e: any) => {
    if (e.target.name === "subject_wise_mapping") {
      setShow(!show);
    }
    setFiltersData({ ...filtersData, [e.target.name]: e.target.checked });
  };

  //   sample format => "cityMapping":[
  //     {
  //         "studentCity": "Amritsar",
  //         "centerCity": ["Amritsar","Patiala"]
  //     },
  //     {
  //         "studentCity": "Chandigarh",
  //         "centerCity": ["Chandigarh"]
  //     },
  //     {
  //         "studentCity": "Ludhiana",
  //         "centerCity": ["Ludhiana"]
  //     }
  // ],

  const getCenterValue = (center: any) => {
    const city_selection = cityMapping.find(
      (data: any) => data.studentCity == center
    );
    return city_selection
      ? city_selection.centerCity.map((city: any) => ({
        label: city,
        value: city,
      }))
      : [];
    // return city_selection ? [city_selection] : [];
  };

  const handleCenterSelection = (e: any, studentSelection: any) => {
    const tempCityMapping = [...cityMapping];
    const index = tempCityMapping.findIndex(
      (data: any) => data.studentCity === studentSelection
    );
    if (index !== -1) {
      tempCityMapping[index].centerCity = e.map((data: any) => data.value);
    } else {
      tempCityMapping.push({
        studentCity: studentSelection,
        centerCity: e.map((data: any) => data.value),
      });
    }
    setCityMapping(tempCityMapping);
  };

  const getExamStats = async () => {
    await MappingServices.getExamStats(examId).then((res) => {
      if (res.status === 200) {
        setStudentCities(res.data.students.studentCities);
        setStatsData({
          cityCapacity: res.data.centers.cityCapacity,
          totalCenters: res.data.centers.totalCenters,
          totalSeats: res.data.centers.totalSeats,
          requiredCount: res.data.students.requiredCount,
          totalStudents: res.data.students.totalStudents,
        });
        setCenterData(
          res.data.centers.centerCities.map((data: any) => {
            return {
              label: data,
              value: data,
            };
          })
        );
      }
    });
  };

  const [showMappingModal, setShowMappingModal] = useState(false);
  const [show, setShow] = useState(false);

  const handleShowChange = () => {
    setShowMappingModal(!showMappingModal);
  };

  const handleSelectChange = async (value: any, label: string) => {
    setFiltersData({ ...filtersData, [label]: value.value });
  };

  const handleDownloadZip = async () => {
    toast.promise(
      MappingServices.downloadSCV(examId).then((res) => {
        if (res.status === 200) {
          window.open(res.data, "_blank");
        }
      }),
      {
        loading: "Downloading..",
        success: "Download Successfully",
        error: "Error While Downloading Zip File ",
      }
    );
  };

  const autoPopulate = () => {
    MappingServices.autoPopulateCenters(examId, maxDistance).then((res) => {
      if (res.status === 200) {
        setPincodeMappingData(res.data.final_pincode_status);

        toast.success("Centers Auto Populated");
      }
    });
  };

  const handleSave = async () => {
    setLoading(true);

    switch (selectedFormula) {
      case "Basic Allocation": {
        await MappingServices.mapStudents(examId, cityMapping, filtersData)
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);

              setLoading(false);
              setMappingDataRes(res.data);
              toast.success("Request Sent");
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data);
          });
        break;
      }
      case "Subject Wise Allocation": {
        await MappingServices.mapBySubject(examId, cityMapping, filtersData).then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setMappingDataRes(res.data);
            toast.success("Request Sent");
          }
        }).catch((err) => {
          setLoading(false);
          toast.error(err.response.data);
        });
        break;
      }
      case "Subject Wise Allocation - Parallel": {
        await MappingServices.mapBySubjectParallel(examId, cityMapping, filtersData).then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setMappingDataRes(res.data);
            toast.success("Students mapped");
          }
        }).catch((err) => {
          setLoading(false);
          toast.error(err.response.data);
        });
        break;
      }
      default: {
        toast.error("Invalid Formula");
      }

    }
  };

  const newPincodeMappingData = pincodeMappingData.map((data: any) => {
    return {
      studentPincode: data.pincode,
      center_id: data.centers.map((id: any) => id._id),
    };
  });

  const payload = {
    pincodeMapping: newPincodeMappingData,
    filters: filtersData,
  };

  const [mappedData, setMappedData] = useState<any>([]);


  const mapPincodeCandidate = async () => {
    setLoading(true);
    await MappingServices.mapPincodeCandidate(examId, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setMappedData(res.data);
          toast.success("Students mapped");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data);
      });
  };

  const [distinctCenterPincodeData, setDistinctCenterPincodeData] =
    useState<any>([]);

  const getAllDistinctCenterPincode = () => {
    MappingServices.getAllDistinctCenterPincode(examId).then((res) => {
      if (res.status === 200) {
        setDistinctCenterPincodeData(res.data);
      }
    });
  };

  const [allPincode, setAllPincode] = useState<any>([]);

  const getAllPinCode = () => {
    MappingServices.getAllPinCode(examId).then((res) => {
      if (res.status === 200) {
        setAllPincode(res.data);
      }
    });
  };

  useEffect(() => {
    getAllDistinctCenterPincode();
    getAllPinCode();

    getExamStats();
  }, []);

  const choices = [
    {
      value: 1,
      label: "Choice 1",
    },
    {
      value: 2,
      label: "Choice 2",
    },
    {
      value: 3,
      label: "Choice 3",
    },
  ];

  const renderMappingSubmit = () => {
    switch (selectedFormula) {
      case "Basic Allocation": {
        return (
          <>
          {mapByDistance ? (
            <> </>
          ) : (
            <Row>
              <Col md={12} className="mt-2">
                <div>
                  {show ? (
                    <Button variant="primary" onClick={handleShowChange}>
                      Show Mapping CSV
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() => setShowModal(true)}
                    >
                      Map Candidates
                    </Button>
                  )}
                  <Button
                    className="ms-2"
                    variant="secondary"
                    onClick={handleDownloadZip}
                  >
                    Download ZIP
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          </>
        )
      }

      case "Subject Wise Allocation": {
        return (
          <Button size="sm" onClick={handleSave} disabled={loading || computation.ongoing}>
            {(loading || computation.ongoing) ? "Request Sent - Check Progress" : "Send Request To Map"}
          </Button>
        )
      }

      case "Subject Wise Allocation - Parallel": {
        return (
          <Button size="sm" onClick={handleSave} disabled={loading || computation.ongoing}>
            {(loading || computation.ongoing) ? "Requst Sent - Check Progress" : "Send Request To Map - Parallel" }
          </Button>
        )
      }
    }
  }

  const renderOptions = () => {
    switch (selectedFormula) {
      case "Basic Allocation": {
        return (
          <Row>
            <Col md={12} className="mt-2">
              <Card>
                <Card.Body>
                  <Form.Group>
                    <Row>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="pwd_center_mandatory"
                          label="Allot only PWD Centers to PWD Students"
                          onChange={handleFiltersChange}
                          checked={filtersData.pwd_center_mandatory}
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="females_in_morning"
                          label="Females in Morning"
                          onChange={handleFiltersChange}
                          checked={filtersData.females_in_morning}
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="pwd_in_morning"
                          label="PWD in Morning"
                          onChange={handleFiltersChange}
                          checked={filtersData.pwd_in_morning}
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="shuffle_students"
                          label="Shuffle Students"
                          onChange={handleFiltersChange}
                          checked={filtersData.shuffle_students}
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="testing"
                          label="Testing"
                          onChange={handleFiltersChange}
                          checked={filtersData.testing}
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="mapByDistance"
                          label="Map By Distance"
                          onChange={(e: any) =>
                            setMapByDistance(e.target.checked)
                          }
                          checked={mapByDistance}
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="equal_gender_distribution"
                          label="Equal Gender Distribution"
                          onChange={handleFiltersChange}
                          checked={filtersData.equal_gender_distribution}
                        />
                      </Col>
                    </Row>
                    {mapByDistance ? (
                      <Row className="mt-4">
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Auto Populate Data with distance
                            </Form.Label>
                            <Form.Control
                              value={maxDistance}
                              onChange={(e: any) =>
                                setMaxDistance(e.target.value)
                              }
                            />
                          </Form.Group>
                          <Button
                            className="mt-2"
                            size="sm"
                            onClick={autoPopulate}
                          >
                            Auto Populate
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="mt-4">
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Default Choice for Male Candidates
                            </Form.Label>
                            <Select
                              options={choices}
                              value={choices.find(
                                (choice) =>
                                  filtersData.male_choice === choice.value
                              )}
                              onChange={(e: any) =>
                                handleSelectChange(e, "male_choice")
                              }
                            />
                          </Form.Group>
                        </Col>

                        <Col md={3}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Default Choice for Female Candidates
                            </Form.Label>
                            <Select
                              options={choices}
                              value={choices.find(
                                (choice) =>
                                  filtersData.female_choice === choice.value
                              )}
                              onChange={(e: any) =>
                                handleSelectChange(e, "female_choice")
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      }
      case "Subject Wise Allocation": {
        return (
          <Row>
            <Col md={12} className="mt-2">
              <Card>
                <Card.Body>
                  <Form.Group>
                    <Row>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="pwd_center_mandatory"
                          label="Allot only PWD Centers to PWD Students"
                          disabled={true}
                          checked={true}
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="shuffle_students"
                          label="Shuffle Students"
                          checked={true}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    {mapByDistance ? (
                      <Row className="mt-4">
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Auto Populate Data with distance
                            </Form.Label>
                            <Form.Control
                              value={maxDistance}
                              onChange={(e: any) =>
                                setMaxDistance(e.target.value)
                              }
                            />
                          </Form.Group>
                          <Button
                            className="mt-2"
                            size="sm"
                            onClick={autoPopulate}
                          >
                            Auto Populate
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="mt-4">
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Default Choice for Male Candidates
                            </Form.Label>
                            <Select
                              options={choices}
                              value={choices.find(
                                (choice) =>
                                  filtersData.male_choice === choice.value
                              )}
                              onChange={(e: any) =>
                                handleSelectChange(e, "male_choice")
                              }
                            />
                          </Form.Group>
                        </Col>

                        <Col md={3}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Default Choice for Female Candidates
                            </Form.Label>
                            <Select
                              options={choices}
                              value={choices.find(
                                (choice) =>
                                  filtersData.female_choice === choice.value
                              )}
                              onChange={(e: any) =>
                                handleSelectChange(e, "female_choice")
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      }

      case "Subject Wise Allocation - Parallel": {
        return (
          <Row>
            <Col md={12} className="mt-2">
              <Card>
                <Card.Body>
                  <Form.Group>
                    <Row>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="pwd_center_mandatory"
                          label="Allot only PWD Centers to PWD Students"
                          disabled={true}
                          checked={true}
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Check
                          type="switch"
                          name="shuffle_students"
                          label="Shuffle Students"
                          checked={true}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    {mapByDistance ? (
                      <Row className="mt-4">
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Auto Populate Data with distance
                            </Form.Label>
                            <Form.Control
                              value={maxDistance}
                              onChange={(e: any) =>
                                setMaxDistance(e.target.value)
                              }
                            />
                          </Form.Group>
                          <Button
                            className="mt-2"
                            size="sm"
                            onClick={autoPopulate}
                          >
                            Auto Populate
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="mt-4">
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Default Choice for Male Candidates
                            </Form.Label>
                            <Select
                              options={choices}
                              value={choices.find(
                                (choice) =>
                                  filtersData.male_choice === choice.value
                              )}
                              onChange={(e: any) =>
                                handleSelectChange(e, "male_choice")
                              }
                            />
                          </Form.Group>
                        </Col>

                        <Col md={3}>
                          <Form.Group>
                            <Form.Label className="fw-bold fs-12">
                              Default Choice for Female Candidates
                            </Form.Label>
                            <Select
                              options={choices}
                              value={choices.find(
                                (choice) =>
                                  filtersData.female_choice === choice.value
                              )}
                              onChange={(e: any) =>
                                handleSelectChange(e, "female_choice")
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      }

    }
  }

  const renderCenterData = () => {
    if (mapByDistance) {
      return (
        <MappingByDistance
          distinctCenterPincodeData={distinctCenterPincodeData}
          allPincode={allPincode}
          pincodeMappingData={pincodeMappingData}
          setPincodeMappingData={setPincodeMappingData}
          mappedData={mappedData}
          mapPincodeCandidate={mapPincodeCandidate}
          loading={loading}
          handleDownloadZip={handleDownloadZip}
        />
      );
    } else {
      return (
        <NotMappingByDistance
          studentCities={studentCities}
          centerData={centerData}
          handleCenterSelection={handleCenterSelection}
          getCenterValue={getCenterValue}
          cityMapping={cityMapping}
          setCityMapping={setCityMapping}
        />
      );
    }
  };

  const renderTabData = () => {
    switch (selectedTab) {
      case "center_count": {
        return (
          <>
            <CenterCount statsData={statsData} />
          </>
        );
      }

      case "required_count": {
        return (
          <>
            <RequiredCount statsData={statsData} />
          </>
        );
      }

      case "Progress": {
        return (
          <>
            <ProgressStatus />
          </>
        )
      }

      case "allotment": {
        return (
          <>
            <Row>
              <Col md={3}>
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 className="text-muted">Total Students</h6>
                      </div>
                      <div className="text-primary h3">
                        {statsData.totalStudents}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 className="text-muted">Total Seats</h6>
                      </div>
                      <div className="text-success h3">
                        {statsData.totalSeats}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 className="text-muted">Total Centers</h6>
                      </div>
                      <div className="text-secondary h3">
                        {statsData.totalCenters}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <Form.Group className="mb-2">
                  <Form.Label className="text-muted fw-bold">
                    Select Formula Type
                  </Form.Label>
                  <Select
                    options={formulas}
                    value={formulas.find(
                      (formula) => selectedFormula == formula.value
                    )}
                    onChange={(e: any) => setSelectedFormula(e.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {renderOptions()}

            {renderCenterData()}

            <Row className="mt-2">
              <Col>
                {renderMappingSubmit()}
              </Col>
            </Row>


           
          </>
        );
      }
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Tabs
            defaultActiveKey="allotment"
            onSelect={(tab: any) => setSelectedTab(tab)}
          >
            <Tab eventKey="allotment" title="Allotment" />
            <Tab eventKey="center_count" title="City Capacity" />
            <Tab eventKey="required_count" title="Required Student Count" />
            <Tab eventKey="Progress" title="Progress Status" />
          </Tabs>
          <div className="mt-3">{renderTabData()}</div>
        </Card.Body>
      </Card>
      <Step4Modal
        show={showModal}
        setShow={() => setShowModal(false)}
        onConfirm={handleSave}
        loading={loading}
        finalData={mappingDataRes}
      />
      <ShowMappingCSV
        show={showMappingModal}
        setShow={() => setShowMappingModal(false)}
        filtersData={filtersData}
        examId={examId}
        cityMapping={cityMapping}
      />
    </>
  );
}
