import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { MappingContext } from "./context";
import { Button, Container } from "react-bootstrap";
import MappingBar, { IJobStepInterface } from "./MappingBar";
import Step1 from "./Steps/Step1";
import Step0 from "./Steps/Step0";
import XcnButton from "../../../components/XcnButton/XcnButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step2b from "./Steps/Step2b";

interface ISelectMapping {
  component: any;
  setSelectedStep: any;
  selectedStep: number;
}

function SelectMappingCom(props: ISelectMapping) {
  return (
    <props.component
      setSelectedStep={props.setSelectedStep}
      selectedStep={props.selectedStep}
    />
  );
}

export default function MappingIndex() {
  const params = useParams();
  const [mappingData, setMappingData] = useState<any>();

  const [selectedStep, setSelectedStep] = useState<number>(0);

  const [steps, setSteps] = useState<IJobStepInterface[]>([
    {
      stepName: "Select File",
      component: Step0,
    },
    {
      stepName: "File Mapping",
      component: Step1,
    },
    {
      stepName: "Center Data",
      component: Step2,
    },
    {
      stepName: "Center Mapping",
      component: Step2b,
    },
    {
      stepName: "Finalize Allotment",
      component: Step4,
    },
  ]);

  return (
    <>
      <MappingContext.Provider value={{ mappingData, setMappingData }}>
        <Container>
          <MappingBar
            steps={steps}
            key={selectedStep}
            selectedStep={selectedStep}
          />
          <SelectMappingCom
            component={steps[selectedStep].component}
            setSelectedStep={setSelectedStep}
            selectedStep={selectedStep}
          />
        </Container>
        <div className="d-flex justify-content-between align-items-center mt-3">
          {selectedStep > 0 && (
            <XcnButton
              text={
                <>
                  <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                  Previous
                </>
              }
              variant="danger"
              onClick={() => setSelectedStep(selectedStep - 1)}
            />
          )}

          {selectedStep <= 3 && (
            <XcnButton
              text={
                <>
                  Next
                  <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                </>
              }
              variant="success"
              onClick={() => setSelectedStep(selectedStep + 1)}
            />
          )}
        </div>
      </MappingContext.Provider>
    </>
  );
}
