import { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AdminServices } from "../../services/admin.service";
import Auth from "../../Auth/Auth";

export default function Login() {

  const navigate = useNavigate();

  const [username, setUsername] = useState<any>();
  const [password, setPassword] = useState<any>();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    await AdminServices.Login({
        email: username,
        password: password
    })
      .then(async(res) => {
        if (res.status === 200) {
          await Auth.authenticate();
          sessionStorage.setItem("authKey", res.data.token)
          navigate('/exam')
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          console.error(err);
        } else if (err.response.status === 500) {
          alert(err.response.data)
        }
      })
  }




    return (
         <div className="bg-light-primary minh100">
            <Container fluid className="d-flex justify-content-center">
                 <Card className="border-0 shadow mb-5"
        style={{ width: "30rem", borderRadius: "0.5rem", marginTop: "4rem" }}
      >
        <Card.Title className="text-center text-primary mb-2 mt-4">
          <h4>
            Admin Login 
          </h4>
        </Card.Title>
        <Card.Body>
          <Card.Text>
            <div className="text-left">
              <Form.Group controlId="formBasicEmail" className="mt-3">
                <Form.Label className="fw-bold">
                  User Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={(e: any) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mt-3" controlId="exampleForm.ControlInput2">
              <Form.Label className="fw-bold">
                  Password
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Password"
                  name="email"
                  onChange={(e: any) => setPassword(e.target.value)}
                />
              
              </Form.Group>

              <div className="form-group mt-3">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
            </Container>
        </div>
    )
}