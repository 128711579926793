import makeRequest, { RequestMethods } from "./makerequest";
import url from "./urls";

export default class StepService {
    static async getStep1(examId: any) {
        return await makeRequest(url.step.step1 + "/" + examId, RequestMethods.GET);
    }

    static async getStep2(examId: any) {
        return await makeRequest(url.step.step2 + "/" + examId, RequestMethods.GET);
    }

    static async getStep3(examId: any) {
        return await makeRequest(url.step.step3 + "/" + examId, RequestMethods.GET);
    }

    static async getStep4(examId: any) {
        return await makeRequest(url.step.step4 + "/" + examId, RequestMethods.GET);
    }
}