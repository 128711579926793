import { faBinoculars, faCheck, faCheckCircle, faCircle, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export enum JobStatusEnum{
    INPROGRESS= "inprogress",
    COMPLETED = "completed",
    NOTVISITED= "notvisited"
}

interface IJobCounter {
    status: JobStatusEnum
    counter: number
    stepName: string
}

export default function JobCounterStatus(props: IJobCounter) {
    switch (props.status) {
        case JobStatusEnum.COMPLETED: return (
            <div className="fw-bold text-success">
                <div className="text-center">
                    <FontAwesomeIcon icon={faCheckCircle} className="ms-2" />
                </div>
                <div>
                    {props.counter}. {props.stepName}
                </div>

            </div>
        )
        case JobStatusEnum.INPROGRESS: return (
            <div className="fw-bold text-primary">
                <div className="text-center">
                    <FontAwesomeIcon icon={faBinoculars} className="ms-2" />
                </div>
                <div>
                    {props.counter}. {props.stepName}
                </div>
            </div>
        )
        case JobStatusEnum.NOTVISITED: return (
            <div className="fw-bold text-secondary">
                <div className="text-center">
                    <FontAwesomeIcon icon={faCircle} className="ms-2" />
                </div>
                <div>
                    {props.counter}. {props.stepName}
                </div>
            </div>
        )
    }
}