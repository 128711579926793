import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminServices } from "../../../services/admin.service";
import { Button, Card, Container, Tab, Table, Tabs } from "react-bootstrap";
import AddExamModal from "../../../components/Modals/Addexam";
import EditExam from "../../../components/Modals/EditExam";

export default function ExamIndex() {
  const [showModal, setShowModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);

  const [showCreateExam, setShowCreateExam] = useState(false);

  const [editExam, setEditExam] = useState<any>(undefined);

  const [examData, setExamData] = useState<any>();

  const navigate = useNavigate();

  const getAllExams = async () => {
    await AdminServices.getAllExams().then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setExamData(res.data.exams);
      }
    });
  };
  console.log(examData, examData);

  const handleDeleteExam = async (examId: any) => {
    await AdminServices.deleteExamById(examId).then((res) => {
      if (res.status === 200) {
        getAllExams();
      }
    });
  };

  useEffect(() => {
    getAllExams();
  }, [showCreateExam, editExam]);

  return (
    <>
      <Container className="d-flex justify-content-end">
        <Button onClick={() => setShowCreateExam(true)} size="sm">
          Create Exam
        </Button>
      </Container>
      <Container fluid className="mt-3">
        {examData &&
          examData.length > 0 &&
          examData.map((exam: any, index: number) => {
            return (
              <Card className="p-3 w-100 mt-2">
                <Card.Title>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {index + 1}. Exam Name: {exam.examName}
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mx-1"
                        onClick={() => navigate("/mapping/" + exam._id)}
                      >
                        View
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        className="mx-1"
                        onClick={() => handleDeleteExam(exam._id)}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="warning"
                        size="sm"
                        className="mx-1"
                        onClick={() => setEditExam(exam._id)}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </Card.Title>
                <Card.Body>
                  <Tabs
                    defaultActiveKey={exam?.examDays[0]?.name}
                    className="mb-3"
                  >
                    {exam.examDays &&
                      exam.examDays.length > 0 &&
                      exam.examDays.map((days: any) => {
                        return (
                          <Tab eventKey={days.name} title={days.name}>
                            <div className="d-flex justify-content-between">
                              <Table size="sm" striped bordered>
                                <thead>
                                  <tr>
                                    <th>Shift Name</th>
                                    <th> Shift Type </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {days.shifts &&
                                    days.shifts.length > 0 &&
                                    days.shifts.map((shift: any) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{shift.name}</td>
                                            <td>{shift.shiftType}</td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          </Tab>
                        );
                      })}
                  </Tabs>
                </Card.Body>
              </Card>
            );
          })}
      </Container>

      <AddExamModal setShow={setShowCreateExam} show={showCreateExam} />

      <EditExam examId={editExam} setExamId={setEditExam} />
    </>
  );
}
