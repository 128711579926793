import { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import Select from "react-select";
import Step4Modal from "../../../../components/Modals/Step4.modal";

interface MapByDistanceProps {
  distinctCenterPincodeData: any;
  allPincode: any;
  pincodeMappingData: any;
  setPincodeMappingData: any;
  mappedData: any;
  mapPincodeCandidate: any;
  loading: any;
  handleDownloadZip: any;
}

const MapByDistance = ({
  distinctCenterPincodeData,
  allPincode,
  pincodeMappingData,
  setPincodeMappingData,
  mappedData,
  mapPincodeCandidate,
  loading,
  handleDownloadZip,
}: MapByDistanceProps) => {
  const optionForSelect = () => {
    return distinctCenterPincodeData.map((item: any) => {
      return {
        label: `${item.centerId} | ${item.pincode}`,
        value: item._id,
        data: item,
      };
    });
  };

  const centerPincodes = (pincode: any) => {
    const centerPincode = pincodeMappingData.find(
      (item: any) => item.pincode === pincode
    );
    if (!centerPincode) return [];
    if (centerPincode.centers.length <= 0) return [];

    return centerPincode.centers.map((item: any) => {
      return {
        label: `${item?.centerId} | ${item.pincode}`,
        value: item?._id,
        data: item,
      };
    });
  };

  const updateCenterPincodes = (e: any, pincode: any) => {
    const centerPincode = pincodeMappingData.findIndex(
      (item: any) => item.pincode === pincode
    );
    if (centerPincode === -1) {
      setPincodeMappingData([
        ...pincodeMappingData,
        { pincode: pincode, centers: e.map((item: any) => item.data) },
      ]);
    } else {
      const temp = [...pincodeMappingData];
      temp[centerPincode].centers = e.map((item: any) => item.data);
      setPincodeMappingData(temp);
    }
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <Row>
      <Col md={12} className="mt-2">
        <Card>
          <Card.Body>
            <Form.Group className="mb-2">
              <Row style={{marginBottom:"15px"}}>
                <Col md={2}>
              <Button variant="primary" onClick={() => setShowModal(true)}>
                    Map Candidates
                  </Button>
                </Col>
                <Col md={2}>
                <Button
                      className="ms-2"
                      variant="secondary"
                      onClick={handleDownloadZip}
                    >
                      Download ZIP
                    </Button>
                </Col>
              </Row>
              <Row>
                <Col md={7}>
                  <Form.Label className="text-muted fw-bold">
                    Student Choices (Pincode)
                  </Form.Label>
                </Col>
                <Col md={3}>
                  <Form.Label className="text-muted fw-bold">
                    Centers (CenterId | Pincode)
                  </Form.Label>
                </Col>
                
              </Row>
            </Form.Group>
            <div>
              {allPincode.map((item: any) => {
                return (
                  <>
                    <Row className="my-2 border border-1 bg-light rounded">
                      <Col md={6}>{item}</Col>
                      <Col md={6}>
                        <Select
                          options={optionForSelect()}
                          isMulti
                          isSearchable
                          isClearable
                          placeholder="Select Center"
                          value={centerPincodes(item)}
                          onChange={(e: any) => {
                            updateCenterPincodes(e, item);
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                );
              })}
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Step4Modal
        show={showModal}
        setShow={() => setShowModal(false)}
        onConfirm={mapPincodeCandidate}
        loading={loading}
        finalData={mappedData}
      />
    </Row>
  );
};

export default MapByDistance;
